/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Bootstrap RTL
@import "bootstrap-rtl/bootstrap-rtl";

// Bootstrap functions
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";

// Argon functions
@import "custom/functions";
@import "custom/variables";
@import "~bootstrap/scss/variables";

// Argon mixins
@import "custom/mixins";

// Bootstrap components
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/jumbotron";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/media";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/print";

// Argon components
@import "custom/components";
@import "custom/utilities";
@import "custom/vendors";

// React differences
@import "react/react-differences";

.row {
    margin-right: 0px;
    margin-left: 0px;
}

.react-bootstrap-table table > tbody > tr > td:nth-child(1),
.react-bootstrap-table table > thead > tr:nth-child(1) > th:nth-child(1) {
    background-color: #f6f9fc;
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    z-index: 1;
}

.react-bootstrap-table th.sortable {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 1;
}

.header.pb-6 {
    padding-bottom: 0rem !important;
}
.svg-container {
    display: inline-block;
    position: relative;
    width: 100%;
    height: 100%;
    padding-bottom: 100%; /* aspect ratio */
    vertical-align: top;
    overflow: hidden;
}
.svg-content-responsive {
    display: inline-block;
    position: absolute;
    top: 10px;
    left: 0;
}
